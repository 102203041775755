(($) => {
  const nav = document.querySelector('.nav-jump .nav__wrapper');
  const body = document.body;

  //Guide mask
  const guide = nav.querySelector('.nav__guide');

  const setMask = () => {
    const box = nav.getBoundingClientRect();
    const top = window.scrollY;
    const offset = box.y - top > 0 ? box.y - top : 0;
    const maskTop = box.y - offset;
    const mask = `linear-gradient(180deg,black 0,black ${maskTop - 24}px,transparent ${maskTop - 24}px,transparent ${box.height + maskTop}px,black ${box.height}px,black 100%)`;
    $(guide).css({
      'top':`-${offset ? top : box.y}px`,
      '-webkit-mask-image':mask,
      'mask-image':mask
    });
  }

  window.addEventListener("resize", setMask);
  window.addEventListener("scroll", setMask);
  window.addEventListener("appInitialized", setMask);

  setMask();

  //Anchors
  const links = Array.from(nav.querySelectorAll('.nav-link'));
  links.forEach(l => {
    const href = l.href.split("#")[1];
    l.anchor = document.querySelector(`[name="${href}"]`);
    ["STABILIZE","STRENGTHEN","SUPPORT"].forEach(label => {
      if (l.innerText.indexOf(label) !== -1) l.innerHTML = l.innerHTML.replace(label,`<span>${label}</span>`);
    })
  });

  activeAnchor = null;

  let anchorTimeout;

  const setActiveAnchor = () => {
    if (links.length) {
      let l = links.length - 1;
      let activeLink,nextAnchor; 
      do {    
        activeLink = links[l];
        nextAnchor = activeLink.anchor;
        l--;
      } while (l >= 0 && (!nextAnchor || nextAnchor.getBoundingClientRect().y > window.innerHeight/2));
      // if (location.hash && nextAnchor.name === "support") {
      //   const hash = location.hash.substring(1,location.hash.length);
      //   if (["strengthen","stabilize","support"].indexOf(hash) !== -1) {
      //     activeLink = links.find(l => l.getAttribute("href") == `#${hash}`);
      //     nextAnchor = activeLink.anchor;
      //   }
      // }
      links.forEach(l => l.classList.remove('active'));
      activeLink.classList.add("active");
      body.classList.remove(`nav-active-${activeAnchor}`);
      activeAnchor = nextAnchor.name;
      body.classList.add(`nav-active-${activeAnchor}`);
    }
  }

  window.addEventListener("resize", setActiveAnchor);
  window.addEventListener("scroll", setActiveAnchor);
  window.addEventListener("appInitialized", setActiveAnchor);

  setActiveAnchor();

})(jQuery)